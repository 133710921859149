import { Component, Point, Room, View } from 'outpost';
import { Player } from './player.ts';
import { CreatureSlot, CreatureSlotKind } from './creature-slot.ts';
import { ABILITY_TRIGGER_TO_ICON, COUNTER_SYMBOL, CROWN_SYMBOL, DECK_RECT, DRAW_ANIMATION_DURATION, FEATHER_SYMBOL, HEALTH_SYMBOL, MANA_SYMBOL, STRENGTH_SYMBOL, UI_STROKE_SIZE } from './constants.ts';
import { GameLayer } from './game-layer.ts';
import { Ability, AbilityTrigger, CreatureStats } from './data/creature-stats.ts';
import { OnCreatureGrow, onCreatureGrow } from './events/on-creature-grow.ts';
import { creatureTooltip } from './widgets/creature-tooltip.ts';
import { onPlayerGainMana } from './events/on-player-gain-mana.ts';

export class Creature implements Component {
    owner: Player;
    baseStats: CreatureStats;
    stats: CreatureStats;
    slot: CreatureSlot | null = null;
    dead: boolean = false;

    constructor(owner: Player, stats: CreatureStats) {
        this.owner = owner;
        this.baseStats = structuredClone(stats);
        this.stats = structuredClone(stats);
    }

    moveToSlot(slot: CreatureSlot, animationDuration: number) {
        let prevSlot = this.slot;

        if (prevSlot) {
            prevSlot.creature = null;
        }

        this.slot = slot;
        slot.creature = this;

        return (view: View) => {
            if (animationDuration === 0) {
                return;
            }

            let prevRect = prevSlot?.rect;
            let startScale = 1;

            if (!prevRect) {
                prevRect = DECK_RECT.stripToMatchAspectRatio(1);
                startScale = 0;
            }

            let rect = slot.rect ?? prevRect;
            let theme = slot.owner.getTheme();

            if (startScale !== 0) {
                startScale = prevRect.width / rect.width;
            }

            prevRect = prevRect.mirror(null, theme.mirrorY);
            rect = rect.mirror(null, theme.mirrorY);

            view.paint({
                duration: animationDuration,
                transformX: { start: prevRect.x - rect.x, end: 0 },
                transformY: { start: prevRect.y - rect.y, end: 0 },
                transformScale: { start: startScale, end: 1 }
            });
        };
    }

    grow(bonusStrength: number, bonusHealth: number) {
        this.stats.strength = Math.max(this.stats.strength + bonusStrength, 0);
        this.stats.health = Math.max(this.stats.health + bonusHealth, 1);
    }

    promote() {
        this.stats.hasCrown = true;
    }

    kill() {
        if (this.slot) {
            this.slot.creature = null;
            // this.slot = null;
        }

        this.dead = true;

        return (view: View) => {
            view.paint({
                duration: 500,
                transformScale: { start: 1, end: 0, duration: 300, delay: 200 }
            });

            view.paint({
                key: 'overlay',
                color: 'tomato',
            });

            view.paint({
                key: 'death',
                text: '☠',
                textSize: '100%'
            });
        };
    }

    canBePlayed(): boolean {
        return this.owner.mana >= this.stats.cost;
    }

    getAdjacentCreatures(processFeather: boolean = false): Creature[] {
        if (!this.slot) {
            return [];
        }

        return Room.getAll(Creature, candidate => {
            return candidate.owner === this.owner
                && candidate.slot?.kind === CreatureSlotKind.Battlefield
                && candidate !== this
                && (Math.abs(candidate.slot.x - this.slot!.x) + Math.abs(candidate.slot.y - this.slot!.y) === 1 || (processFeather && candidate.stats.feather));
        });
    }

    getAttackTarget(): Creature | undefined {
        let targetSlots = Room.getAll(CreatureSlot, slot => {
            return slot.kind === CreatureSlotKind.Battlefield
                && slot.owner !== this.owner
                && slot.x === this.slot?.x
                && slot.creature !== null;
        });
        let candidates = targetSlots.sort((a, b) => a.y - b.y).map(slot => slot.creature!);

        return candidates.at(0);
    }

    triggerAbilitiesWith(trigger: AbilityTrigger, triggerArg: number = 0) {
        for (let ability of this.stats.abilities) {
            if (ability.trigger === trigger && (ability.triggerArg === undefined || ability.triggerArg === triggerArg)) {
                this.triggerAbility(ability);
            }
        }
    }

    triggerAbility(ability: Ability) {
        Room.waitForDuration(200);

        if (ability.effect === 'draw') {
            for (let i = 0; i < ability.arg1; ++i) {
                this.owner.draw();
            }
        } else if (ability.effect === 'mana') {
            Room.emitEvent(onPlayerGainMana, this.owner, ability.arg1);
        } else if (ability.effect === 'train') {
            for (let creature of this.getAdjacentCreatures()) {
                Room.emitEvent(onCreatureGrow, {
                    creature,
                    bonusStrength: ability.arg1,
                    bonusHealth: ability.arg2 ?? 0
                });
            }
        } else if (ability.effect === 'grow') {
            Room.emitEvent(onCreatureGrow, {
                creature: this,
                bonusStrength: ability.arg1,
                bonusHealth: ability.arg2 ?? 0
            });
        }

        Room.waitForAnimation(400);
    }

    render(view: View): void {
        let rect = this.slot?.rect;
        let theme = this.slot?.owner.getTheme();

        if (!rect || !theme) {
            return;
        }

        rect = rect.mirror(null, theme.mirrorY);

        let [attackRect, healthRect] = rect.fromBottomInwards('*', '30%').split('left', ['50%w']);

        view.paint({
            rect,
            layerId: GameLayer.Base,
            color: 'lightgray',
        });

        if (theme.isOpponent && this.slot?.kind === CreatureSlotKind.Hand) {
            view.paint({
                key: 'back',
                text: '?',
                textBold: true,
                offsetY: 3
            });

            return;
        }

        let imgPath = `../assets/images/${this.stats.name.replaceAll(' ', '')}.png`;

        view.paint({
            key: 'image',
            rect: rect.fromTopLeftInwards('40%h', '40%h', 0),
            image: imgPath
        });

        view.paint({
            key: 'attack',
            rect: attackRect,
            text: `${this.stats.strength}${STRENGTH_SYMBOL}`,
            textSize: '80%'
        });

        view.paint({
            key: 'health',
            rect: healthRect,
            text: `${this.stats.health}${HEALTH_SYMBOL}`,
            textSize: '80%'
        });

        view.paint({
            key: 'cost',
            rect: rect.fromTopRightInwards('40%h', '40%h').translate(-10, 0),
            text: `${this.stats.cost}${MANA_SYMBOL}`
        });

        if (this.stats.hasCrown) {
            view.paint({
                key: 'crown',
                rect: rect.fromTopLeftInwards('13%h', '13%h', 10),
                text: CROWN_SYMBOL
            });
        }

        let abilityIcons: string[] = [];

        if (this.stats.feather) {
            abilityIcons.push(FEATHER_SYMBOL);
        }

        if (this.stats.counter) {
            abilityIcons.push(COUNTER_SYMBOL);
        }

        for (let { trigger, triggerArg, effect, arg1, arg2 = 0 } of this.stats.abilities) {
            let icon = ABILITY_TRIGGER_TO_ICON[trigger];

            if (triggerArg !== undefined) {
                icon += `@o50%{@0.6{${triggerArg}}}`;
            }

            icon += '🠖';

            if (effect === 'mana') {
                for (let i = 0; i < arg1; ++i) {
                    icon += MANA_SYMBOL;
                }
            } else if (effect === 'draw') {
                for (let i = 0; i < arg1; ++i) {
                    icon += '🂠';
                }
            } else if (effect === 'grow') {
                icon += `🔥${arg1}/${arg2}`;
            } else if (effect === 'train') {
                icon += `🗡️${arg1}/${arg2}`;
            }

            abilityIcons.push(icon);
        }

        let abilityRect = rect.fromCenter('*', '20%').translate(0, '15%');

        if (abilityIcons.length > 0) {
            view.paint({
                key: 'abilities',
                rect: abilityRect,
                text: abilityIcons.join(','),
                textPadding: 2
            });
        }
    }

    tooltip(view: View): void {
        let player = Room.getLocalPlayer();

        if (this.owner === player || this.slot?.kind === CreatureSlotKind.Battlefield) {
            creatureTooltip(view, this.stats);
        }
    }

    highlightHovered(view: View): void {
        let color = this.slot?.owner.getTheme()?.fgColor;

        view.paint({
            key: 'stroke',
            cursor: 'pointer',
            strokeSize: UI_STROKE_SIZE * 1.5,
            color
        });
    }

    highlightDrag(view: View, dragStartPosition: Point): void {
        let offset = dragStartPosition.getVectorTo(Room.getPointerPosition());

        view.paint({
            detectable: false,
            layerId: GameLayer.Overlay,
            autoRefresh: true,
            transformX: offset.x,
            transformY: offset.y,
            transformScale: 0.8
        });
    }
}
globalThis.ALL_FUNCTIONS.push(Creature);