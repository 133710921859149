import { Button, Component, PromiseWithResolvers, Room, View, makePromise } from 'outpost';
import { Player } from '../player.ts';
import { GameState } from '../game-state.ts';
import { VIEWPORT_RECT } from '../constants.ts';

export class EndOfGamePanel implements Component {
    onComplete: PromiseWithResolvers = makePromise();
    button = new Button('Leave');

    constructor() {
        this.button.setStyle({ base: { background: { color: 'blanchedalmond' } } });
    }

    async $ok() {
        Room.setInteractionPriority(1);
        await Room.waitForItemSelection(this.button);
        this.onComplete.resolve();

        return Room.getInteractionSignature();
    }

    render(view: View): void {
        let rect = VIEWPORT_RECT.scale(0.8);
        let [_, buttonRect] = rect.split('top', ['75%']);
        let player = Room.getLocalPlayer(Player)!;
        let gameState = player.game.state;
        let text = '';

        if (gameState === GameState.Draw) {
            text = 'Draw!';
        } else if ((gameState === GameState.P1Win && player.game.p1 ===  player) || (gameState === GameState.P2Win && player.game.p2 ===  player)) {
            text = 'Victory!';
        } else if ((gameState === GameState.P1Win && player.game.p1 !==  player) || (gameState === GameState.P2Win && player.game.p2 !==  player)) {
            text = 'Defeat.';
        } else {
            text = 'Bug...';
        }

        view.paint({
            rect,
            color: 'white',
            borderRadius: '10%',
        });

        view.paint({
            key: 'stroke',
            color: 'black',
            strokeSize: '2%',
        });

        view.paint({
            key: 'text',
            text,
            textSize: '20%',
        });

        buttonRect = buttonRect.scale(0.7).stripToMatchAspectRatio(4);

        view.addChild(this.button, buttonRect);
    }

    waitForData(): Promise<void> {
        return this.onComplete.promise;
    }
}
globalThis.ALL_FUNCTIONS.push(EndOfGamePanel);