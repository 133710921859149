import { Component, Rect, View } from 'outpost';
import { Creature } from './creature.ts';
import { Player } from './player.ts';
import { GameLayer } from './game-layer.ts';
import { UI_STROKE_SIZE } from './constants.ts';

export enum CreatureSlotKind {
    Battlefield,
    Hand,
    Other,
};

export class CreatureSlot implements Component {
    owner: Player;
    kind: CreatureSlotKind;
    creature: Creature | null = null;
    x: number;
    y: number;
    rect: Rect | null;

    constructor(owner: Player, kind: CreatureSlotKind, x: number, y: number, rect: Rect | null = null) {
        this.owner = owner;
        this.kind = kind;
        this.x = x;
        this.y = y;
        this.rect = rect;
    }

    render(view: View): void {
        if (!this.rect) {
            return;
        }

        let theme = this.owner.getTheme();

        view.paint({
            rect: this.rect,
            layerId: GameLayer.Base,
            color: theme.bgColor,
            mirrorY: theme.mirrorY
        });

        // view.paint({
        //     key: 'debug',
        //     text: `(${this.x}, ${this.y})`,
        // });
        
        view.paint({
            key: 'stroke',
            layerId: GameLayer.Border,
            color: theme.fgColor,
            strokeSize: UI_STROKE_SIZE,
            detectable: false
        });
    }
}
globalThis.ALL_FUNCTIONS.push(CreatureSlot);