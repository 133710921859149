import { Component, Room, View } from 'outpost';
import { Player } from './player.ts';
import { EndTurnButton } from './end-turn-button.ts';
import { GameLayer, LAYERS } from './game-layer.ts';
import { Creature } from './creature.ts';
import { CreatureSlot, CreatureSlotKind } from './creature-slot.ts';
import { TurnCounterLabel } from './widgets/turn-counter-label.ts';
import { GameState } from './game-state.ts';
import { EndOfGamePanel } from './widgets/end-of-game-panel.ts';
import { onGameStart } from './events/on-game-start.ts';
import { onCreaturePlay } from './events/on-creature-play.ts';
import { onPlayerPass } from './events/on-player-pass.ts';

export class GameRoom implements Component {
    p1!: Player;
    p2!: Player;
    activePlayer: Player | null = null;
    turnIndex: number = 0;
    state: GameState = GameState.Running;

    endTurnButton = new EndTurnButton();
    turnCounterLabel = new TurnCounterLabel();

    onRoomStart(): void {
        let players = Room.getAll(Player);

        if (players.length < 2) {
            let player = new Player({ id: 'bot' });

            Room.spawn(player);
            players.push(player);
        }

        this.p1 = players[0].init(players[1]);
        this.p2 = players[1].init(players[0]);
        this.activePlayer = this.p1;

        Room
            .refresh(this)
            // .setAnimationsEnabled(false)
            .waitForDuration(1000)
            .emitEvent(onGameStart)
            .refresh(this)
            // .setAnimationsEnabled(true);
    }

    onMount(): void {
        Room.initLayers(LAYERS);
    }

    async $playCard() {
        let player = Room.getSourcePlayer();
        let source = await Room.waitForUserInput({
            selectable: () => Room.getAll(Creature, creature => creature.slot?.kind === CreatureSlotKind.Hand && creature.owner === player),
            isEnabled: (creature) => this.activePlayer === player && creature.canBePlayed(),
            highlightDisabled: {
                key: 'overlay',
                color: 'black',
                alpha: 0.2
            },
            selectionTrigger: 'down'
        });

        let creature = source.selection;
        let validSlots = Room.getAll(CreatureSlot, slot => slot.owner === creature.owner && !slot.creature && slot.kind === CreatureSlotKind.Battlefield);
        let invalidSlots = Room.getAll(CreatureSlot, slot => !(slot.owner === creature.owner && !slot.creature && slot.kind === CreatureSlotKind.Battlefield));

        Room.addModifier(creature, (view, creature) => creature.highlightDrag(view, source.position));

        let target = await Room.waitForUserInput({
            selectable: validSlots,
            dimmed: invalidSlots,
            selectionTrigger: 'up',
            abortOnInvalidSelection: true,
            highlightEnabled: {
                key: 'stroke',
                color: '#00AA00',
                cursor: 'grabbing'
            },
            highlightHovered: {
                key: 'stroke',
                strokeSize: { add: 3 }
            },
            highlightDimmed: {
                key: 'overlay',
                color: 'black',
                alpha: 0.2
            }
        });

        await Room.waitForServerResponse();

        Room.emitEvent(onCreaturePlay, creature, target.selection);
    }

    async $endTurn() {
        if (!this.activePlayer) {
            return;
        }

        let player = Room.getSourcePlayer(Player);

        await Room.waitForUserInput({
            selectable: this.endTurnButton,
            isEnabled: () => this.activePlayer === player
        });
        await Room.waitForServerResponse();

        Room.emitEvent(onPlayerPass, player);
    }

    async $enfOfGame() {
        if (!this.isCompleted()) {
            return;
        }

        await Room.prompt(new EndOfGamePanel());
        await Room.waitForServerResponse();

        Room.removePlayerFromRoom(Room.getId(), Room.getSourcePlayerId());
    }

    async $forfeit() {
        let player = Room.getSourcePlayer(Player);

        await Room.waitForButton('Shift_KeyF');
        await Room.waitForServerResponse();

        if (this.p1 === player) {
            this.state = GameState.P2Win;
        } else if (this.p2 === player) {
            this.state = GameState.P1Win;
        }
    }

    async $forceOpponentPlay() {
        await Room.waitForButton('Ctrl_Shift_KeyX');

        let player = Room.getSourcePlayer(Player).opponent;
        let creature = player.hand.find(slot => slot.creature !== null)?.creature;
        let slot = Room.get(CreatureSlot, slot => slot.owner === player && slot.kind === CreatureSlotKind.Battlefield && slot.creature === null);

        if (!slot || !creature) {
            return;
        }

        await Room.waitForServerResponse();

        Room.emitEvent(onCreaturePlay, creature, slot)
    }

    async $forceOpponentEndTurn() {
        await Room.waitForButton('Ctrl_Shift_KeyC');
        await Room.waitForServerResponse();

        let player = Room.getSourcePlayer(Player).opponent;

        if (this.activePlayer !== player) {
            return;
        }

        Room.emitEvent(onPlayerPass, player);
    }

    // async $kill() {
    //     let input = await Room.waitForUserInput({
    //         selectable: () => Room.getAll(Creature, creature => creature.slot?.kind === CreatureSlotKind.Battlefield),
    //         selectionButton: 'MouseRight'
    //     });

    //     await Room.waitForServerResponse();

    //     // Room.flash(input.selection, triggerAnimation);
    // }

    isCompleted(): boolean {
        return this.state !== GameState.Running;
    }

    setActivePlayer(player: Player) {
        this.activePlayer = player;
    }

    render(view: View): void {
        view.paint({
            layerId: GameLayer.Base
        });

        view.addChild(this.endTurnButton);
        view.addChild(this.turnCounterLabel);
    }
}
globalThis.ALL_FUNCTIONS.push(GameRoom);