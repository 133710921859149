import { Room } from 'outpost';
import { Creature } from '../creature.ts';
import { onPlayerDamage } from './on-player-damage.ts';

export function onCreatureDeath(creature: Creature) {
    Room.update(creature, creature => creature.kill())
        .waitForAnimation(300);

    creature.triggerAbilitiesWith('death');

    Room.despawn(creature)

    if (creature.stats.hasCrown) {
        Room.emitEvent(onPlayerDamage, creature.owner, 1)
            .waitForAnimation(200)
    }
}