import { Component, Rect, RectLike, View, registerSerializableAsset } from 'outpost';
import { DECK_RECT, HEALTH_RECT, HEALTH_SYMBOL, MANA_RECT, MANA_SYMBOL, NAME_LABEL_RECT, UI_BORDER_RADIUS } from './constants.ts';
import { Player } from './player.ts';

class PlayerLabel implements Component {
    private rect: Rect;

    constructor(rect: RectLike, getText: (player: Player) => string) {
        this.rect = Rect.from(rect);
    }

    getTextToDisplay(player: Player): string {
        return '';
    }

    render(view: View): void {
        let player = view.getParent(Player);
        let { bgColor } = player.getTheme();
        let text = this.getTextToDisplay(player);

        view.paint({
            rect: this.rect,
            color: bgColor,
            borderRadius: UI_BORDER_RADIUS
        });

        view.paint({
            key: 'text',
            text,
            textSize: 35,
            borderRadius: 0
        });
    }
}

function makePlayerLabel(rect: Rect, getText: (player: Player) => string) {
    return registerSerializableAsset(class extends PlayerLabel {
        constructor() {
            super(rect, getText);
        }

        getTextToDisplay(player: Player): string {
            return getText(player);
        }
    });
}

export const NameLabel = makePlayerLabel(NAME_LABEL_RECT, player => `${player.id}`);
export const DeckLabel = makePlayerLabel(DECK_RECT, player => `Deck: ${player.deck.length}`);
export const HealthLabel = makePlayerLabel(HEALTH_RECT, player => `${player.health} ${HEALTH_SYMBOL}`);
export const ManaLabel = makePlayerLabel(MANA_RECT, player => `${player.mana} ${MANA_SYMBOL}`);
globalThis.ALL_FUNCTIONS.push(PlayerLabel);