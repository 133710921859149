export type CreatureStats = {
    name: string;
    cost: number;
    health: number;
    strength: number;
    feather: boolean;
    counter: boolean;
    abilities: Ability[];
    hasCrown: boolean;
};

export type AbilityTrigger = 'play' | 'death' | 'trigger';
export type AbilityEffect = 'mana' | 'draw' | 'train' | 'grow';
export type Ability = {
    trigger: AbilityTrigger;
    triggerArg?: number;
    effect: AbilityEffect;
    arg1: number;
    arg2?: number;
};

// export const DEFAULT_CREATURE_STATS: CreatureStats = {
//     name: 'Default',
//     cost: 1,
//     health: 1,
//     strength: 1,
//     feather: false,
//     counter: false,
//     abilities: [],
//     hasCrown: false
// };

export type SimpleCreatureStats = [string, number, number, number, ...SimpleCreatureAbility[]];
export type SimpleCreatureAbility = 'feather' | 'counter' | [AbilityTrigger | `play:${number}`, AbilityEffect, number, number?];

export function formatCreatureStats(stats: SimpleCreatureStats): CreatureStats {
    let [name, cost, strength, health, ...abilities] = stats;
    let result: CreatureStats = {
        name,
        cost,
        health,
        strength,
        feather: abilities.includes('feather'),
        counter: abilities.includes('counter'),
        abilities: [],
        hasCrown: false
    };

    for (let ability of abilities) {
        if (!Array.isArray(ability)) {
            continue;
        }

        let [trigger, effect, arg1, arg2] = ability;
        let triggerArg: number | undefined = undefined;

        if (trigger.includes(':')) {
            triggerArg = parseInt(trigger.split(':')[1]);
            trigger = 'play';
        }

        result.abilities.push({
            trigger: trigger as AbilityTrigger,
            effect,
            arg1,
            arg2,
            triggerArg
        });
    }

    return result;
}