import { ColorLike, Component, Room, View } from 'outpost';
import { GameRoom } from './game-room.ts';
import { END_TURN_BUTTON_RECT, UI_BORDER_RADIUS } from './constants.ts';

export class EndTurnButton implements Component {
    render(view: View): void {
        let activePlayer = view.getParent(GameRoom).activePlayer;
        let text = '';
        let color: ColorLike = 'blanchedalmond';

        if (activePlayer) {
            if (Room.getLocalPlayer() === activePlayer) {
                text = 'End turn';
                color = 'lightgreen';
            } else {
                text = 'Opponent turn';
            }
        }

        view.paint({
            rect: END_TURN_BUTTON_RECT,
            color,
            borderRadius: UI_BORDER_RADIUS
        });

        view.paint({
            key: 'stroke',
            color: 'sienna',
            strokeSize: 5
        });

        if (text) {
            view.paint({
                key: 'text',
                text,
                textSize: '50%'
            });
        }
    }

    highlightDisabled(view: View): void {
        view.paint({
            key: 'overlay',
            color: 'black',
            alpha: 0.2
        });
    }

    highlightHovered(view: View): void {
        view.paint({
            key: 'stroke',
            strokeSize: { add: 2 },
            cursor: 'pointer'
        });
    }
}
globalThis.ALL_FUNCTIONS.push(EndTurnButton);