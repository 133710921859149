import { Room } from 'outpost';
import { Creature } from '../creature.ts';
import { CreatureSlot, CreatureSlotKind } from '../creature-slot.ts';
import { DRAW_ANIMATION_DURATION } from '../constants.ts';
import { onCheckVictory } from './on-check-victory.ts';
import { onCreatureTrigger } from './on-creature-trigger.ts';

export function onCreaturePlay(creature: Creature, targetSlot: CreatureSlot) {
    let isFromOpponent = creature.owner !== Room.getLocalPlayer();
    let animationDuration = isFromOpponent ? DRAW_ANIMATION_DURATION : 0;
    let shouldPromote = Room.getAll(CreatureSlot, slot => slot.kind === CreatureSlotKind.Battlefield && slot.owner === targetSlot.owner && slot.x === targetSlot.x)
        .every(slot => slot.creature === null);

    Room.update(creature.owner, player => player.mana -= creature.stats.cost)
        .update(creature, creature => creature.moveToSlot(creature.owner.highlightSlot, animationDuration))
        .waitForAnimation(isFromOpponent ? 1000 : 0)
        .update(creature, creature => creature.moveToSlot(targetSlot, animationDuration))
        .waitForAnimation();

    if (shouldPromote) {
        Room.update(creature, creature => creature.promote());
    }

    let creaturesToTrigger = creature.getAdjacentCreatures(true);

    creature.triggerAbilitiesWith('play', creaturesToTrigger.length);

    for (let adjacent of creaturesToTrigger) {
        Room.emitEvent(onCreatureTrigger, adjacent);
    }

    Room.emitEvent(onCheckVictory);

    if (creature.owner.opponent.hasPassed) {
        return;
    }

    Room.update(creature.owner.game, game => game.setActivePlayer(creature.owner.opponent))
        .refresh();
}