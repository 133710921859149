import { Outpost } from 'outpost';
import { GameRoom } from './game-room.ts';
import { Player } from './player.ts';
import { AUTO_JOIN } from './constants.ts';

async function main() {
    Outpost.startGameHub({
        gameConstructor: GameRoom,
        playerConstructor: Player,
        playerCount: [2, 2],
        autoJoin: AUTO_JOIN,
        gameName: 'Swap',
        matchmaking: true,
    });
}

main();