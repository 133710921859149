import { Component, View } from 'outpost';
import { UI_BORDER_RADIUS, VIEWPORT_RECT } from '../constants.ts';

export class AlertBox implements Component {
    text: string;

    constructor(text: string) {
        this.text = text;
    }

    render(view: View): void {
        let rect = VIEWPORT_RECT.withWidth(800).stripToMatchAspectRatio(4);

        view.paint({
            duration: 1000,
            rect,
            color: 'white',
            borderRadius: UI_BORDER_RADIUS,
        });

        view.paint({
            key: 'text',
            text: this.text,
            textSize: '50%'
        });

        view.paint({
            key: 'stroke',
            color: 'black',
            strokeSize: 10
        });
    }
}
globalThis.ALL_FUNCTIONS.push(AlertBox);