import { Point, PointLike, View } from 'outpost';
import { AbilityEffect, AbilityTrigger, CreatureStats } from '../data/creature-stats.ts';
import { COUNTER_SYMBOL, CROWN_SYMBOL, FEATHER_SYMBOL, HEALTH_SYMBOL, MANA_SYMBOL, STRENGTH_SYMBOL, TOOLTIP_RECT, UI_BORDER_RADIUS, UI_STROKE_SIZE } from '../constants.ts';

const COST_RECT = TOOLTIP_RECT.fromTopRightCentered(80, 80, 10);
const [STRENGTH_RECT, HEALTH_RECT] = TOOLTIP_RECT.fromBottomInwards('*', '30%').split('left', ['50%w']);
const TOOLTIP_DELAY = 1000;

export function creatureTooltip(view: View, stats: CreatureStats, center?: PointLike, delay: number = TOOLTIP_DELAY) {
    let rect = TOOLTIP_RECT;

    if (center) {
        let { x, y } = Point.resolve(center);

        rect = TOOLTIP_RECT.withCenter(x, y);
    }

    view.paint({
        linger: 100,
        delay,
        rect,
    });

    view.paint('background', {
        color: 'white',
        borderRadius: UI_BORDER_RADIUS
    });

    view.paint('border', {
        color: 'black',
        strokeSize: UI_STROKE_SIZE,
        borderRadius: UI_BORDER_RADIUS
    });

    view.paint('name', {
        rect: rect.fromTopInwards('*', '20%'),
        text: stats.name,
        textSize: '50%',
    });

    // view.paint('cost-background', {
    //     rect: COST_RECT,
    //     color: 'white',
    // });

    // view.paint('cost-border', {
    //     rect: COST_RECT,
    //     strokeSize: UI_STROKE_SIZE,
    //     color: 'black',
    // });

    // view.paint('cost-text', {
    //     rect: COST_RECT,
    //     text: `${stats.cost}${MANA_SYMBOL}`,
    //     textSize: '50%'
    // });

    // view.paint({
    //     key: 'attack',
    //     rect: STRENGTH_RECT,
    //     text: `${stats.strength}${STRENGTH_SYMBOL}`,
    //     textSize: '80%'
    // });

    // view.paint({
    //     key: 'health',
    //     rect: HEALTH_RECT,
    //     text: `${stats.health}${HEALTH_SYMBOL}`,
    //     textSize: '80%'
    // });

    let bullets: string[] = [];

    bullets.push(`@bold{${stats.cost}${MANA_SYMBOL}:} Costs ${stats.cost} mana to be played.`);
    bullets.push(`@bold{${stats.strength}${STRENGTH_SYMBOL}:} Deals ${stats.strength} damage when triggered.`);
    bullets.push(`@bold{${stats.health}${HEALTH_SYMBOL}:} Can take ${stats.health} damage before dying.`);

    bullets.push(``);

    if (stats.hasCrown) {
        bullets.push(CROWN_DESCRIPTION);
    }

    if (stats.feather) {
        bullets.push(FEATHER_DESCRIPTION);
    }

    if (stats.counter) {
        bullets.push(COUNTER_DESCRIPTION);
    }

    bullets.push(``);

    for (let { effect, trigger, arg1, arg2, triggerArg } of stats.abilities) {
        let x1 = arg1;
        let x2 = arg2 ?? 0;
        let triggerStr = TRIGGER_TO_STRING[trigger];
        let effectStr = EFFECT_TO_STRING[effect].replaceAll('{X1}', x1.toString()).replaceAll('{X2}', x2.toString());

        if (triggerArg !== undefined) {
            triggerStr += `if it triggers exactly ${triggerArg} creatures, `;
        }

        bullets.push(`${triggerStr}${effectStr}`);
    }

    view.paint({
        key: 'description',
        rect: rect.fromBottomInwards('*', '80%'),
        text: bullets.map(str => str ? `• ${str}` : '').join('\n@\n'),
        verticalAlign: 'top',
        horizontalAlign: 'left',
        textMultiline: true,
        textSize: 25,
        textPadding: 10
    });
}

const TRIGGER_TO_STRING: { [Key in AbilityTrigger]: string } = {
    trigger: 'When this creature is triggered, ',
    play: 'When this creature is played, ',
    death: 'When this creature dies, '
};

const EFFECT_TO_STRING: { [Key in AbilityEffect]: string } = {
    draw: `draw {X1} card.`,
    mana: `gain {X1} mana.`,
    grow: `give it +{X1}/+{X2}.`,
    train: `give adjacent creatures +{X1}/+{X2}.`,
};

const FEATHER_DESCRIPTION = `@bold{Feather ${FEATHER_SYMBOL}:} This creature is always triggered when you play another creature.`;
const COUNTER_DESCRIPTION = `@bold{Counter ${COUNTER_SYMBOL}:} When this creature is attacked, it attacks back.`;
const CROWN_DESCRIPTION = `@bold{Crown ${CROWN_SYMBOL}:} When this creature dies, you lose 1 health. @italic{@small{When you play a creature on an empty column, it gains a crown. At the end of the turn, the player with the least crowns loses 1 health.}}`