import { SimpleCreatureStats } from './creature-stats.ts';

export const DEFAULT_CREATURE: SimpleCreatureStats = ['Grunt', 1, 1, 1, ['play', 'grow', 1, 0]];

export const CREATURE_DATA: SimpleCreatureStats[] = [
    ['Nunu', 0, 0, 1],
    ['Pantheon', 1, 2, 1, 'counter'],
    ['Kalista', 1, 0, 1, ['play:1', 'grow', 1, 1], ['play:1', 'train', 1, 1]],
    ['Braum', 2, 1, 1, ['play', 'train', 1, 1]],
    ['Kog Maw', 2, 2, 1, ['trigger', 'grow', 2, 0]],
    ['Heimerdinger', 2, 1, 1, ['play:3', 'draw', 1], ['play:3', 'mana', 1]],
    ['Smolder', 3, 1, 1, 'feather', ['trigger', 'grow', 1, 1]],
    ['Karthus', 3, 4, 1, ['death', 'draw', 1]],
    ['Cassiopeia', 4, 4, 4, ['trigger', 'grow', 0, 1], ['trigger', 'mana', 1]],
    ['Nami', 4, 1, 4, ['trigger', 'train', 2, 2]],
    ['Pyke', 2, 6, 1, ['trigger', 'grow', -2, 0]],
    ['Hwei', 3, 1, 1, ['play:1', 'grow', 1, 1], ['play:2', 'mana', 3], ['play:3', 'draw', 2]],
    ['Viktor', 3, 2, 2, ['trigger', 'mana', 1]],
    ['Varus', 3, 3, 2, 'feather'],
    ['Yuumi', 1, 0, 1, ['play:1', 'grow', 0, 2], ['play:1', 'train', 2, 1]],
    ['Lissandra', 4, 3, 2, ['trigger', 'grow', 0, 2]],
    ['Ezreal', 4, 1, 5, 'feather', ['trigger', 'grow', 2, 0]],
    ['Ivern', 3, 0, 3, ['play', 'train', 0, 3], ['play:0', 'grow', 3, 0]],
    ['Yorick', 1, 1, 1, ['play:0', 'grow', 1, 2]],
    ['Draven', 5, 3, 3, 'counter', 'feather', ['trigger', 'grow', 1]],
    ['Thresh', 1, 1, 2, ['play:0', 'grow', -1, 0], ['play:1', 'train', 1, 2]],
    ['Soraka', 2, 0, 3, ['play', 'train', 0, 1], ['trigger', 'train', 0, 1]],
    ['KSante', 3, 1, 5, ['trigger', 'grow', 1, -1]],
    ['Sona', 0, 1, 1, ['play:2', 'mana', 3], ['play', 'train', -2, 0]],
    ['Tahm Kench', 2, 2, 3, ['play:1', 'train', 0, 1], ['play:1', 'grow', -1, 0]],
    ['Sion', 3, 3, 3, 'counter'],
    ['Gragas', 2, 3, 3],
    ['Taliyah', 2, 2, 2, ['play:0', 'draw', 1], ['play:1', 'mana', 1]],
    ['Zac', 3, 0, 3, ['trigger', 'grow', 0, 3], ['play:2', 'mana', 2]],
    ['Singed', 1, 1, 1, ['death', 'mana', 2], ['play:0', 'draw', 1]],
];