import { Component, Room, View } from 'outpost';
import { GameRoom } from '../game-room.ts';
import { TURN_COUNTER_LABEL_RECT, UI_BORDER_RADIUS } from '../constants.ts';

export class TurnCounterLabel implements Component {
    render(view: View): void {
        let game = Room.get(GameRoom);

        view.paint({
            rect: TURN_COUNTER_LABEL_RECT,
            color: 'blanchedalmond',
            borderRadius: UI_BORDER_RADIUS,
        });

        view.paint({
            key: 'text',
            text: `Turn ${game.turnIndex + 1}`,
            textSize: '50%'
        });
    }
}
globalThis.ALL_FUNCTIONS.push(TurnCounterLabel);