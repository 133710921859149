import { View } from 'outpost';
import { UI_STROKE_SIZE } from '../constants.ts';
import { GameLayer } from '../game-layer.ts';

export function triggerAnimation(view: View) {
    let rect = view.getRect();

    view.paint({
        key: 'highlight',
        rect,
        duration: 500,
        easing: 'quadratic-out',
        layerId: GameLayer.Overlay,
        strokeSize: UI_STROKE_SIZE,
        scale: { end: 1.3 },
        color: 'green',
        alpha: { start: 1, end: 0.4 }
    });
}