import { Room } from 'outpost';
import { Player } from '../player.ts';
import { STARTING_HAND_SIZE } from '../constants.ts';

export function onGameStart() {
    let players = Room.getAll(Player);

    for (let player of players) {
        player.shuffleDeck();

        for (let i = 0; i < STARTING_HAND_SIZE; ++i) {
            player.draw();
        }
    }

    Room.waitForAnimation();
}