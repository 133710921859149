import { Room } from 'outpost';
import { Creature } from '../creature.ts';
import { onCreatureDamage } from './on-creature-damage.ts';
import { triggerAnimation } from '../animations/trigger-animation.ts';
import { Missile } from '../animations/missile.ts';

export function onCreatureTrigger(creature: Creature) {
    let target = creature.getAttackTarget();

    Room.flash(creature, triggerAnimation)
        .waitForAnimation();

    creature.triggerAbilitiesWith('trigger');

    if (target) {
        Room.flash(new Missile(creature, target))
            .waitForAnimation()
            .emitEvent(onCreatureDamage, {
                source: creature,
                target,
                amount: creature.stats.strength,
                fromCounter: false
            });
    }
}