import { Rect } from 'outpost';
import { PlayerTheme } from './player-theme.ts';
import { AbilityTrigger } from './data/creature-stats.ts';

export const DEBUG = false;
export const AUTO_JOIN = DEBUG;

export const BATTLEFIELD_SIZE = 3;
export const MAX_HAND_SIZE = 5;
export const STARTING_HAND_SIZE = 3;
export const STARTING_HEALTH = 5;
export const STARTING_MANA = 1;

export const VIEWPORT_RECT = Rect.from([1600, 900]);
export const BATTLEFIELD_RECT = VIEWPORT_RECT.fromBottomRightInwards(450, 450).translate(-120, 0);
export const HAND_RECT = VIEWPORT_RECT.fromBottomInwards('55%', 300).translate(-300, 50).stripToMatchAspectRatio(MAX_HAND_SIZE);
export const NAME_LABEL_RECT = new Rect(225, 620, 250, 100);
export const DECK_RECT = NAME_LABEL_RECT.fromRightOutwards(200, '*', 50);
export const HEALTH_RECT = DECK_RECT.fromRightOutwards(100, '*', 50)
export const MANA_RECT = HEALTH_RECT.fromRightOutwards('*', '*', 50);
export const END_TURN_BUTTON_RECT = new Rect(HAND_RECT.x, VIEWPORT_RECT.y, 400, 100);
export const TURN_COUNTER_LABEL_RECT = END_TURN_BUTTON_RECT.withX1(NAME_LABEL_RECT.x1, 150).withHeight(80);
export const TOOLTIP_RECT = new Rect(HAND_RECT.x, VIEWPORT_RECT.y, 600, 600);
export const HIGHLIGHT_RECT = Rect.square(END_TURN_BUTTON_RECT.x, END_TURN_BUTTON_RECT.y, 400);

export const SELF_THEME: PlayerTheme = { bgColor: 'skyblue', fgColor: 'royalblue', mirrorY: null, isOpponent: false };
export const OPPONENT_THEME: PlayerTheme = { bgColor: 'lightpink', fgColor: 'red', mirrorY: VIEWPORT_RECT.y, isOpponent: true };
export const UI_BORDER_RADIUS = 10;
export const UI_STROKE_SIZE = 5;

export const DRAW_ANIMATION_DURATION = 300;

export const HEALTH_SYMBOL = '@0.8{❤️}';
export const MANA_SYMBOL = '@0.8{💎}';
export const STRENGTH_SYMBOL = '⚔';
export const COUNTER_SYMBOL = `💢`;
export const FEATHER_SYMBOL = `🪶`;
export const CROWN_SYMBOL = `@0.8{👑}`;

export const ABILITY_TRIGGER_TO_ICON: { [Key in AbilityTrigger]: string } = {
    'trigger': '🗲',
    'play': '🂠',
    'death': '💀'
};