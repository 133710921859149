import { Component, Rect, Room, View } from 'outpost';
import { GameLayer } from '../game-layer.ts';

export function floatingText(view: View, text: string) {
    let rectStart = new Rect(view.rect.x, view.rect.y, 200, 50);
    let rectEnd = rectStart.translate(0, -50);

    view.paint({
        key: 'floating-text',
        duration: 500,
        easing: 'quadratic-out',
        layerId: GameLayer.Overlay,
        rect: { start: rectStart, end: rectEnd },
        text
    });
}

const FLOATING_TEXT_SIZE = 40;

export class FloatingText implements Component {
    bar: boolean = true;
    target: Component;
    text: string;

    constructor(target: Component, text: string) {
        this.target = target;
        this.text = text;
    }

    render(view: View): void {
        let targetRect = Room.getViewRect(this.target) ?? Rect.ZERO;
        let { x: mirrorX, y: mirrorY } = Room.getView(this.target)!.getMirror();
        let rectStart = new Rect(targetRect.x, targetRect.y, 200, FLOATING_TEXT_SIZE);
        let rectEnd = rectStart.translate(0, -50);

        view.paint({
            key: 'floating-text',
            detectable: false,
            duration: 500,
            easing: 'quadratic-out',
            layerId: GameLayer.Overlay,
            rect: { start: rectStart, end: rectEnd },
            text: this.text,
            mirrorX,
            mirrorY
        });
    }
}
globalThis.ALL_FUNCTIONS.push(FloatingText);