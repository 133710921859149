import { Room } from 'outpost';
import { GameRoom } from '../game-room.ts';
import { GameState } from '../game-state.ts';

export function onCheckVictory() {
    let game = Room.get(GameRoom);
    let p1Lost = game.p1.health <= 0;
    let p2Lost = game.p2.health <= 0;

    if (p1Lost && p2Lost) {
        game.state = GameState.Draw;
    } else if (p1Lost) {
        game.state = GameState.P2Win;
    } else if (p2Lost) {
        game.state = GameState.P1Win;
    }
}