import { Room } from 'outpost';
import { Creature } from '../creature.ts';
import { FloatingText } from '../animations/floating-text.ts';

export type OnCreatureGrow = {
    creature: Creature;
    bonusStrength: number;
    bonusHealth: number;
};

export function onCreatureGrow(evt: OnCreatureGrow) {
    Room.update(evt.creature, () => evt.creature.grow(evt.bonusStrength, evt.bonusHealth))
        .flash(new FloatingText(evt.creature, `+${evt.bonusStrength}/${evt.bonusHealth}`));
}