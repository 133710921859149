import { Creature } from '../creature.ts';
import { FloatingText } from '../animations/floating-text.ts';
import { triggerAnimation } from '../animations/trigger-animation.ts';
import { Missile } from '../animations/missile.ts';
import { Room } from 'outpost';
import { onCreatureDeath } from './on-creature-death.ts';

export type OnCreatureDamage = {
    source: Creature;
    target: Creature;
    amount: number;
    fromCounter: boolean;
};

export function onCreatureDamage(evt: OnCreatureDamage) {
    Room
        // .flash(this.target, view => floatingText(view, `-${this.amount}❤️`))
        .flash(new FloatingText(evt.target, `-${evt.amount}❤️`))
        .waitForAnimation()
        .update(evt.target, () => evt.target.stats.health -= evt.amount);

    if (!evt.fromCounter && evt.target.stats.counter) {
        let counterTarget = evt.target.getAttackTarget();

        if (counterTarget) {
            Room
                .flash(evt.target, triggerAnimation)
                .waitForAnimation()
                .flash(new Missile(evt.target, counterTarget))
                .waitForAnimation()
                .emitEvent(onCreatureDamage, {
                    source: evt.target,
                    target: counterTarget,
                    amount: evt.target.stats.strength,
                    fromCounter: true
                });
        }
    }

    if (evt.target.stats.health <= 0) {
        Room.waitForDuration(300)
            .emitEvent(onCreatureDeath, evt.target);
    }
}