import { Room } from 'outpost';
import { AlertBox } from '../animations/alert-box.ts';
import { GameRoom } from '../game-room.ts';
import { STARTING_MANA } from '../constants.ts';
import { onPlayerDamage } from './on-player-damage.ts';
import { onCheckVictory } from './on-check-victory.ts';

export function onTurnStart() {
    let game = Room.get(GameRoom);

    Room.flash(new AlertBox(`Turn ${game.turnIndex + 2}`))
        .waitForAnimation()
        .update(game, game => {
            game.turnIndex += 1;
        })
        .update([game.p1, game.p2], player => {
            player.hasPassed = false;
            player.mana = STARTING_MANA + game.turnIndex;
        });

    game.p1.draw();
    game.p2.draw();

    Room.waitForAnimation(500);

    let p1CrownCount = game.p1.getCrownCount();
    let p2CrownCount = game.p2.getCrownCount();

    if (p1CrownCount > p2CrownCount) {
        Room.emitEvent(onPlayerDamage, game.p2, 1);
    } else if (p2CrownCount > p1CrownCount) {
        Room.emitEvent(onPlayerDamage, game.p1, 1);
    }

    Room.emitEvent(onCheckVictory);
}