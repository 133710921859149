import { Component, Room, View } from 'outpost';
import { Creature } from '../creature.ts';

const MISSILE_SIZE = 25;

export class Missile implements Component {
    source: Creature;
    target: Creature;

    constructor(source: Creature, target: Creature) {
        this.source = source;
        this.target = target;
    }

    render(view: View): void {
        let startRect = Room.getViewRect(this.source)!.withSize(MISSILE_SIZE, MISSILE_SIZE);
        let endRect = Room.getViewRect(this.target)!.withSize(MISSILE_SIZE, MISSILE_SIZE);

        view.paint({
            duration: 500,
            rect: { start: startRect, end: endRect },
            color: 'black',
            borderRadius: '50%'
        });
    }
}
globalThis.ALL_FUNCTIONS.push(Missile);