import { Component, Grid, Room, View, makeArray, shuffleArray } from 'outpost';
import { Creature } from './creature.ts';
import { CREATURE_DATA, DEFAULT_CREATURE } from './data/creature-data.ts';
import { BATTLEFIELD_RECT, BATTLEFIELD_SIZE, DEBUG, DRAW_ANIMATION_DURATION, HAND_RECT, HIGHLIGHT_RECT, MAX_HAND_SIZE, OPPONENT_THEME, SELF_THEME, STARTING_HEALTH, STARTING_MANA } from './constants.ts';
import { CreatureSlot, CreatureSlotKind } from './creature-slot.ts';
import { PlayerTheme } from './player-theme.ts';
import { GameRoom } from './game-room.ts';
import { GameLayer } from './game-layer.ts';
import { DeckLabel, HealthLabel, ManaLabel, NameLabel } from './player-label.ts';
import { formatCreatureStats } from './data/creature-stats.ts';

export class Player implements Component {
    id: string;
    game!: GameRoom;
    opponent!: Player;
    battlefield: Grid<CreatureSlot> = new Grid({
        width: BATTLEFIELD_SIZE,
        height: BATTLEFIELD_SIZE,
        makeItem: (x, y) => new CreatureSlot(this, CreatureSlotKind.Battlefield, x, y)
    });
    hand: CreatureSlot[] = makeArray(MAX_HAND_SIZE, i => new CreatureSlot(this, CreatureSlotKind.Hand, i, 0));
    deck: Creature[] = [];
    health: number = STARTING_HEALTH;
    mana: number = STARTING_MANA;
    hasPassed: boolean = false;

    nameLabel = new NameLabel();
    deckLabel = new DeckLabel();
    healthLabel = new HealthLabel();
    manaLabel = new ManaLabel();
    highlightSlot = new CreatureSlot(this, CreatureSlotKind.Other, 0, 0, HIGHLIGHT_RECT);

    constructor(params: { id: string }) {
        this.id = params.id;
    }

    init(opponent: Player): this {
        this.game = Room.get(GameRoom);
        this.opponent = opponent;

        for (let slot of this.battlefield.items) {
            Room.spawn(slot);
        }

        for (let slot of this.hand) {
            Room.spawn(slot);
        }

        BATTLEFIELD_RECT.asGrid({
            items: this.battlefield.items,
            rowSize: this.battlefield.width,
            columnSize: this.battlefield.height,
            margin: '1%',
            itemCallback(slot, rect) {
                slot.rect = rect;
                Room.spawn(slot);
            },
        });

        HAND_RECT.asGrid({
            items: this.hand,
            rowSize: this.hand.length,
            columnSize: 1,
            margin: 5,
            itemCallback(slot, rect) {
                slot.rect = rect;
                Room.spawn(slot);
            }
        });

        for (let stats of CREATURE_DATA) {
            let creature = new Creature(this, formatCreatureStats(stats));

            if (DEBUG) {
                creature = new Creature(this, formatCreatureStats(DEFAULT_CREATURE));
            }

            Room.spawn(creature);
            this.deck.push(creature);
        }

        Room.update(this);

        return this;
    }

    getCrownCount(): number {
        return this.battlefield.items.reduce((acc, slot) => acc + (slot.creature?.stats.hasCrown ? 1 : 0), 0);
    }

    shuffleDeck() {
        shuffleArray(this.deck, Room.getRandomNumber);
    }

    draw() {
        let card = this.deck.pop()!;
        let slot = this.hand.find(slot => slot.creature === null);

        if (!slot) {
            return;
        }

        Room
            .update(this)
            .update(card, card => card.moveToSlot(slot, DRAW_ANIMATION_DURATION))
            .waitForDuration(100)
    }

    getTheme(): PlayerTheme {
        let isSelf = this === Room.getLocalPlayer();

        return isSelf ? SELF_THEME : OPPONENT_THEME;
    }

    render(view: View): void {
        let theme = this.getTheme();

        view.paint({
            layerId: GameLayer.Base,
            mirrorY: theme.mirrorY
        });

        view.addChild(this.nameLabel);
        view.addChild(this.deckLabel);
        view.addChild(this.healthLabel);
        view.addChild(this.manaLabel);
    }
}
globalThis.ALL_FUNCTIONS.push(Player);