import { Room } from 'outpost';
import { Player } from '../player.ts';
import { GameRoom } from '../game-room.ts';
import { onTurnStart } from './on-turn-start.ts';

export function onPlayerPass(player: Player) {
    let game = Room.get(GameRoom);

    if (!game.activePlayer) {
        return;
    }

    let bothPassed = game.activePlayer.opponent.hasPassed;

    Room.update(game.activePlayer, player => { player.hasPassed = true; })
        .update(game, game => { game.activePlayer = game.activePlayer!.opponent; });

    if (bothPassed) {
        Room.emitEvent(onTurnStart);
    }

    Room.refresh();
}